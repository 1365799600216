import { DEFAULT_CURRENCY_CODE, ErrorHandler, NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GraphQLModule } from './graphql/graphql.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
import localeDEExtra from '@angular/common/locales/extra/de';
import { MatDateFnsModule } from "@angular/material-date-fns-adapter";
import { GlobalErrorHandler } from "./misc/global-error-handler";
import { ServerErrorInterceptor } from "./interceptors/server-error.interceptor";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MAT_TABS_CONFIG } from "@angular/material/tabs";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { de } from 'date-fns/locale';
import { MatDialogModule } from "@angular/material/dialog";

registerLocaleData(localeDE, 'de-DE', localeDEExtra);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    GraphQLModule,
    MatDateFnsModule,
    MatSnackBarModule,
    MatDialogModule // Added related to usage in DialogService of @pd/ngx-components
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true},
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: MAT_DATE_LOCALE, useValue: de },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    { provide: MAT_TABS_CONFIG, useValue: {animationDuration: '0ms', stretchTabs: false} },
    provideHttpClient(withInterceptorsFromDi())
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
