import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { HttpBatchLink, HttpLink } from 'apollo-angular/http';
import { environment } from '../../environments/environment';
import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';
import { setContext } from "@apollo/client/link/context";
import { HttpHeaders } from "@angular/common/http";

const uri = environment.api;

export function createApollo(httpLink: HttpLink, httpBatchLink: HttpBatchLink): ApolloClientOptions<any> {
  const auth = setContext((operation, context) => {
    const token = localStorage.getItem('jwt');
    if (token === null) {
      return {};
    } else {
      return {
        headers: new HttpHeaders({
          Authorization: token,
        })
      }
    }
  })

  const httpLinkHandler = httpLink.create({
    uri,
    headers: new HttpHeaders({
      'Apollo-Require-Preflight': 'true'
    }),
    extractFiles: (body) => extractFiles(body, isExtractableFile)
  });

  const httpBatchLinkHandler = httpBatchLink.create({
    uri,
    headers: new HttpHeaders({
      'Apollo-Require-Preflight': 'true'
    }),
  });

  const apolloLink = ApolloLink.split(
    operation => operation.getContext().useMultipart,
    httpLinkHandler,
    httpBatchLinkHandler,
  );

  return {
    link: ApolloLink.from([auth, apolloLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache'
      },
      watchQuery: {
        fetchPolicy: 'no-cache'
      }
    }
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, HttpBatchLink],
    },
  ],
})
export class GraphQLModule {}
